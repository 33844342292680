import React, { useState, useRef } from "react"
import styled from "styled-components"
import banner from "../../../images/openday/implantologia/implantologia-banner.png"
import opendayLogo from "../../../images/openday/implantologia/openday-logo.png"
import font from "../../../fonts/fonts.module.scss"
import { Button } from "../../buttons"
import { handleSubmit } from "../../../custom/gtag-form"

import fbTrack from "../../../custom/fbTrack"

import setaDown from "../../../images/marcarConsulta/setaDown.svg"
import checkbox from "../../../images/marcarConsulta/caixa.svg"
import checked from "../../../images/marcarConsulta/visto_caixa.svg"
import { Link } from "gatsby"
import { Fade } from "react-reveal"

const axios = require("axios")

// Container para o Banner
const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  .openday-implantologia {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    padding-top: 159px;
    z-index: 1;
    top: 0;
    gap: 5vw;
    height: 100vh;
    justify-content: space-between;

    .banner-openInfo {
      grid-column: 7 / -1;
    }
  }

  .info-banner {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .openday-logo {
      max-width: 300px;
      margin: auto;
      padding-bottom: 53px;
    }

    .title {
      margin: 0 100px;

      @media only screen and (max-width: 1440px) {
        margin: 0 68px;
      }

      .top {
        font-size: clamp(2rem, 0.2292rem + 2.3611vw, 3.0625rem);
        text-align: left;
      }

      .middle {
        font-size: clamp(3.375rem, 0.6667rem + 3.6111vw, 5rem);
        text-align: center;
        text-transform: uppercase;
      }

      .bottom {
        font-size: clamp(2.75rem, 0.25rem + 3.3333vw, 4.25rem);
        text-align: right;
      }
    }
  }
  .date-info {
    grid-column: 7 / -1;
    position: relative;
    bottom: 370px;
    color: #fff;

    @media only screen and (max-width: 1440px) {
      bottom: 300px;
    }

    .middle {
      font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
      text-transform: uppercase;
      margin: 0 100px;
    }

    .bottom {
      font-size: clamp(1.5625rem, 1.0417rem + 0.6944vw, 1.875rem);
      margin: 0 100px;
      padding-bottom: 20px;
    }
  }

  .container-layout {
    background: #e6d1c0;
    margin-top: -5px;
  }
`

// Estilo da Imagem do Banner
const BannerImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: relative;
`

// Caixa do Formulário sobreposta na parte inferior direita
const FormBox = styled.form`
  width: 100%;
  background-color: #fff;
  padding: 50px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 86px;
  z-index: 1;

  .form-title {
    text-align: right;
    font-weight: bold;
    font-size: 48px;
    color: #000;

    @media only screen and (max-width: 1440px) {
      font-size: 32px;
    }
  }
  .sub-text {
    font-size: 24px;
    color: #e6d3c3;
    text-align: right;
    padding: 9px 0 69px 0;

    @media only screen and (max-width: 1440px) {
      font-size: 20px;
      padding: 9px 0 34px 0;
    }
  }
`

// Caixa para o Texto na parte inferior esquerda
const TextBox = styled.div`
  grid-column: 1 / 6;
  padding: 100px 0;
`

// Estilo para o título, subtítulo e texto
const Title = styled.p`
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #000;
  font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
`

const SubTitle = styled.h2`
  margin-bottom: 45px;
  color: #000;
  margin-top: -1rem;
  text-align: right;
  font-size: clamp(1.875rem, 2.9167rem + -1.3889vw, 1.25rem);
`

const Description = styled.p`
  font-size: clamp(1.5rem, 1.9167rem + -0.5556vw, 1.25rem);
  line-height: 1.5;
  color: #000;

  li {
    list-style-type: none;
    margin-bottom: 1vh;
  }
  ul {
    padding-top: 20px;
    color: #000;
  }
`
const Date = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  .date {
    grid-column: 1 / 7;
    font-size: clamp(1rem, 0.0625rem + 1.25vw, 1.5625rem);
    margin-bottom: 7px;
    text-align: center;
    color: #fff;
  }

  @media only screen and (min-width: 1921px) {
    .grid-container {
      padding-left: 5vw;
    }
  }
`

// Estilo do Formulário
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  .btn {
    display: flex;
    justify-content: center;

    .btnMarcar {
      width: calc(140px + 60 * (100vw - 320px) / 1600);
      height: calc(30px + 24 * (100vw - 320px) / 1600);
      font-size: calc(14px + 6 * (100vw - 320px) / 1600);

      @media only screen and (min-width: 1920px) {
        font-size: 20px;
        width: 200px;
        height: 54px;
      }

      letter-spacing: 0.1em;
      background-color: #b7a99a;
      color: #ffffff;
      text-align: center;
      line-height: 0;
      z-index: 1;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      user-select: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .btn img {
      height: 50%;
    }
  }
  .input-box {
    position: relative;
    font-size: 12px;
    display: flex;
    align-items: center;

    label {
      width: 7vw;
      margin-top: -5vh;

      @media only screen and (max-width: 1440px) {
        width: 10vw;
        margin-top: -3vh;
      }
    }

    .error{
    }

    .fill {
      width: 100%;
      margin-bottom: 25px;
    }

    .radioNews {
      display: flex;
      width: 100%;
      margin: 0 0 2vh 3.5vw;

      .textflex {
        width: 100%;
        display: flex;
      }

      @media only screen and (max-width: 1440px) {
        margin: 0 0 2vh 2.8vw;
      }
    }

    .setaDown {
      position: absolute;
      width: 16px;

      @media only screen and (max-width: 768px) {
        width: calc(15px + 5 * (100vw - 320px) / 448);
      }

      right: 10px;
      top: 25%;
      transform: translateY(-20%);
      pointer-events: none;
      cursor: pointer;
      z-index: 3;

      @media only screen and (max-width: 1440px) {
        top: 28%;
      }
    }

    .label {
      position: absolute;
      top: -50%;
      left: 0%;
    }

    input,
    select {
      z-index: 1;
      padding: 0 3%;
      height: 50px;
      border: 1px solid #e5d0bf;
      outline: none;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 50px;

      @media only screen and (max-width: 1440px) {
        margin-bottom: 25px;
        height: 40px;
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";

      option {
        font-size: 18px;
      }
    }

    label,
    input {
      text-transform: uppercase;
      font-size: 15px;
      color: rgb(35, 31, 32);
      font-weight: 300;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__tab-loop {
      position: relative;
    }

    .react-datepicker-popper {
      position: absolute;
      top: 0;
      transform: translateY(-65%) !important;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .radioOptions {
      display: flex;

      .radioOption {
        display: flex;
        gap: 5%;
        align-items: center;
        color: #808080;
        cursor: pointer;
        margin-top: 1%;

        .checkmark {
          width: 30px;
          height: 30px;
          margin-right: 20px;
          cursor: pointer;

          img {
            width: 20px;
            height: 30px;
            padding: 0%;
            margin: 0%;
          }
        }
      }
    }
  }
`

const ResponsePop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow: hidden !important;
  background: #000000d9;
  -webkit-backdrop-filter: blur(50px);

  .response {
    background: #e6d1c0;
    padding: 124px 100px;
    z-index: 99;
    text-align: center;
    font-size: clamp(1.875rem, 0.8333rem + 1.3889vw, 2.5rem);
    width: 70%;
    position: relative;

    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px 20px;
    }
  }
`

const BannerWithTextAndForm = () => {
  const form = useRef()
  const [response, setResponse] = useState("")
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const [name, setName] = useState("");
  const [selectedClinic, setSelectedClinic] = useState("default");
  const [error, setError] = useState("")

  const validateName = (name) => {
    const namePattern = /^[A-Za-zÀ-ÿ\s]+$/;  // Expressão regular para letras (incluindo acentuadas)
    return namePattern.test(name);         // Retorna true se a expressão regular passar
  };

  const handleClinicChange = (e) => {
    setSelectedClinic(e.target.value);
    console.log("CLÍNICA SELECIONADA: " + e.target.value);
  };

  var checker = []

  function doSubmit(e) {
    e.preventDefault()
    checkValues()

    // Verificar se a clínica foi selecionada
    if (selectedClinic === "default") {
      setResponse("Por favor, selecione uma clínica.");
      console.log("CLÍNICA SELECIONADA: " + selectedClinic);
      return;
    }

    // Verificar se nome tem apenas letras
    if (!validateName(name)) {
      setError('O nome deve conter apenas letras.');
      return;
    }

    if (checker.length < 1) {
      e.preventDefault()
      setResponse("")
      var formData = new FormData()
      formData.append("name", document.querySelector("#nameConsulta").value)
      formData.append("email", document.querySelector("#emailConsulta").value)
      formData.append("phone", document.querySelector("#phoneConsulta").value)
      {
        selectedClinic === "default" ?
          setResponse("Por favor, selecione uma clínica") :
          formData.append("clinica", document.querySelector("#clinicConsulta").value)
      }
      formData.append("opendayimplantologia", true)
      formData.append("newsletter", newsCheck ? true : false)
      for (const value of formData.values()) {
        console.log(value);
      }
      axios
        .post("https://carreiradentalclinic.pt/form-contact.php", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data; charset=UTF-8",
          },
        })
        .then(res => {
          console.log(res)
          res.data === "success" || res.data === "successsuccess"
            ? success()
            : setResponse(res.data)
        })
    } else {
      e.preventDefault()
      setResponse("Faltam os seguintes campos: " + checker + ".")
    }
  }

  const success = () => {
    var clinicTosend = document.querySelector("#clinicConsulta").value

    setResponse("Formulário enviado com sucesso!")
    setIsPopupVisible(true)
    fbTrack("trackCustom", "Envio de formulário - Página de Open Day")
    if (typeof window !== "undefined") {
      window.gtag("event", "form_open_day_implantologia", {
        form: "Formulário Open Day - Implantologia",
        clinica: clinicTosend,
      })
    }

    form.current.reset()
  }

  function checkValues() {
    checker = []
    let checkerTemp = []
    if (!document.querySelector("#nameConsulta").value) {
      checkerTemp.push("nome")
    }
    if (!document.querySelector("#emailConsulta").value) {
      checkerTemp.push("email")
    }
    if (document.querySelector("#phoneConsulta").value === "true") {
      checkerTemp.push("telefone")
    }
    if (document.querySelector("#clinicConsulta").value === "true") {
      checkerTemp.push("clínica")
    }
    if (!polCheck) {
      if (checkerTemp === []) {
        checkerTemp.push("aceitar os nossos termos e políticas")
      } else {
        checkerTemp.push("e aceitar os nossos termos e políticas")
      }
    }
    checker = checkerTemp
  }

  const [newsCheck, setNewsCheck] = useState(true)
  const [polCheck, setPolCheck] = useState(false)

  function checkNewsletter() {
    document.querySelector("#newsletter").checked = !document.querySelector(
      "#newsletter"
    ).checked
    setNewsCheck(!newsCheck)
  }
  function checkPolitica() {
    document.querySelector("#politica").checked = !document.querySelector(
      "#politica"
    ).checked
    setPolCheck(!polCheck)
  }

  const handleCloseSuccess = () => {
    setIsPopupVisible(false) // Função para fechar o popup
  }

  return (
    <BannerContainer>
      {/* Imagem do Banner */}
      <BannerImage
        src={banner}
        alt="Dentista-com-implante-dentario"
        title="Open Day - Implantologia Avançada"
      />

      <div className="container-layout">
        <div className="grid-container">
          {/* Caixa de Texto na parte inferior esquerda */}
          <TextBox>
            <Title className={font.rB}>CONDIÇÕES ESPECIAIS</Title>
            <SubTitle className={font.rR}>
              Para recuperar o seu bem-estar!
            </SubTitle>
            <Description className={font.rR}>
              No mês da Implantologia Avançada, na clínica dentária CARREIRA
              DENTAL CLINIC, descubra como a <b>dentição fixa com implantes,</b>
              com recurso à <b>técnica All-on-4,</b> é a solução mais cómoda,
              rápida e eficaz para recuperar o seu sorriso.
              <ul className={font.rB}>
                <li> • Raio-X Panorâmico;</li> <li> • Scan Intraoral;</li>
                <li> • Registo Fotográfico.</li>
              </ul>
            </Description>
          </TextBox>
          <div className="date-info">
            <Fade delay={700}>
              <p className={font.rB + " middle"}>
                RECUPERE O SEU
                <br /> SORRISO
              </p>
            </Fade>
            <Fade right delay={800}>
              <p className={font.rR + " bottom"}> com condições especiais</p>
            </Fade>
            <Fade bottom>
              <FormBox id="form-openday">
                <div style={{ width: "33vw" }}>
                  <p className={font.rB + " form-title"}>
                    As vagas são limitadas!
                  </p>
                  <p className={font.rB + " sub-text"}>GARANTA JÁ A SUA!</p>
                </div>
                <StyledForm
                  method="post"
                  action=""
                  iv
                  className="form"
                  ref={form}
                >
                  <div className="input-box">
                    <label className={font.rB}>Clínica *</label>
                    <select
                      name="clinica"
                      className={font.rM}
                      id="clinicConsulta"
                      defaultValue="default"
                      required
                      onChange={handleClinicChange}
                    >
                      <option value="default" disabled hidden>Selecione uma clínica</option>
                      <option value="Porto">Porto</option>
                      <option value="Aveiro">Aveiro</option>
                      <option value="Águeda">Águeda</option>
                      <option value="Viseu">Viseu</option>
                      <option value="Oliveira de Azeméis">Oliveira de Azeméis</option>
                    </select>
                    <img src={setaDown} alt="Selecionar" className="setaDown" />
                  </div>
                  <div className="input-box">
                    <label className={font.rB}>Nome *</label>
                    <div style={{ width: '100%' }}>
                      <input
                        type="text"
                        name="nome"
                        className={font.rM}
                        id="nameConsulta"
                        style={{ textTransform: "none" }}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                      />
                      {name && !/^[a-zA-Z]+$/.test(name) && (
                        <div style={{ color: 'red', fontSize: '0.8rem', textAlign: 'center' }} className="error">O nome deve conter apenas letras.</div>
                      )}
                    </div>
                  </div>

                  <div className="input-box">
                    <label className={font.rB}>Email *</label>
                    <input
                      type="text"
                      name="email"
                      className={font.rM}
                      id="emailConsulta"
                      style={{ textTransform: "none" }}
                      required
                    />
                  </div>
                  <div className="input-box" id="telefone">
                    <label className={font.rB}>Telefone *</label>
                    <input
                      type="tel"
                      name="telefone"
                      className={font.rM}
                      id="phoneConsulta"
                      style={{ textTransform: "none" }}
                      required
                    />
                  </div>
                  <div className="input-box" style={{ color: '#000' }}>
                    <label className={font.rB}></label>
                    <span className={font.rR + " fill"}>
                      * CAMPOS DE PREENCHIMENTO OBRIGATÓRIO
                      <br />
                      Este formulário de contacto corresponde ao procedimento
                      normal de envio de um email diretamente para a nossa
                      caixa de correio. Os seus dados não são armazenados nem
                      utilizados para qualquer outro fim.
                    </span>
                  </div>
                  <div className="input-box">
                    <div className="radioNewsAll">
                      <label className="radioNews">
                        <span className="checkmark">
                          <img
                            src={newsCheck ? checked : checkbox}
                            alt="Newsletter"
                          />
                        </span>
                        <input
                          type="checkbox"
                          name="newsletter"
                          value="newsletter"
                          id="newsletter"
                          onClick={checkNewsletter}
                        />
                        <p
                          className={font.rSB}
                          style={{
                            paddingLeft: "23px",
                            fontSize: "0.875rem",
                            textTransform: "none",
                          }}
                        >
                          Quero receber notícias de saúde oral no meu email
                        </p>
                      </label>
                      <label className="radioNews">
                        <span className="checkmark">
                          <img
                            src={polCheck ? checked : checkbox}
                            alt="Política de Privacidade"
                          />
                        </span>
                        <input
                          type="checkbox"
                          name="politica"
                          value="politica"
                          id="politica"
                          onClick={checkPolitica}
                        />
                        <p
                          className={font.rSB + " textflex"}
                          style={{
                            paddingLeft: "23px",
                            fontSize: "0.875rem",
                            textTransform: "none",
                          }}
                        >
                          Aceito os
                          <Link to="/politica_de_privacidade">
                            &nbsp;termos de política e privacidade*
                          </Link>
                        </p>
                      </label>
                    </div>
                  </div>
                  <div className="btn">
                    <Button
                      styleless
                      className="btnMarcar"
                      onClick={e => doSubmit(e)}
                    >
                      <p className={font.rSB}>ENVIAR</p>
                    </Button>
                  </div>
                </StyledForm>
                <div style={{ color: '#000', textAlign: 'center', marginTop: '2vh', fontWeight: '500' }} className={font.rM + " response"}>{response}</div>
              </FormBox>
            </Fade>
            {isPopupVisible && (
              <ResponsePop>
                <div
                  className={font.rB + " response"}
                  id={
                    response === "Formulário enviado com sucesso!"
                      ? "contacto-sucesso"
                      : "contacto-fail"
                  }
                >
                  <div onClick={handleCloseSuccess} className="close-btn">
                    X
                  </div>
                  A SUA MENSAGEM FOI ENVIADA COM SUCESSO. PROMETEMOS SER BREVES
                  NA RESPOSTA.
                  <br />
                  <br />
                  <p>Até já!</p>
                </div>
              </ResponsePop>
            )}
          </div>
        </div>
        <div className="openday-implantologia">
          <div class="grid-container">
            <div className="banner-openInfo">
              <Fade>
                <div className="info-banner">
                  <div className="title">
                    <Fade left>
                      <p className={font.rR + " top"}>Mês da</p>
                    </Fade>
                    <Fade delay={700}>
                      <p className={font.rB + " middle"}>implantologia</p>
                    </Fade>
                    <Fade right delay={800}>
                      <p className={font.rR + " bottom"}> avançada</p>
                    </Fade>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <Date>
            <div className="grid-container">
              <p className={font.rR + " date"}>
                <b>26 Nov •</b> Oliveira de Azeméis | <b>28 e 29 Nov •</b> Porto
              </p>
              <p className={font.rR + " date"}>
                <b>03 Dez •</b> Viseu | <b>05 Dez •</b> Águeda |{" "}
                <b>06 Dez • </b>
                Aveiro
              </p>
            </div>
          </Date>
        </div>
      </div>
    </BannerContainer>
  )
}

export default BannerWithTextAndForm