import React, { useState, useRef } from "react"
import styled from "styled-components"
import banner from "../../../images/openday/implantologia/implantologia-banner-mobile.png"
import font from "../../../fonts/fonts.module.scss"
import { Button } from "../../buttons"
import { handleSubmit } from "../../../custom/gtag-form"
import opendayLogo from "../../../images/openday/implantologia/openday-logo.png"

import setaDown from "../../../images/marcarConsulta/setaDown.svg"
import checkbox from "../../../images/marcarConsulta/caixa.svg"
import checked from "../../../images/marcarConsulta/visto_caixa.svg"
import { Link } from "gatsby"
import fbTrack from "../../../custom/fbTrack"

const axios = require("axios")

// Container para o Banner
const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  .container-layout {
    background: #e6d1c0;
  }

  .openday-implantologia {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 86px;
    z-index: 1;
    bottom: 0;
    height: 100%;
    gap: 52vw;
    width: 100%;
    padding-top: 120px;
  }

  .info-banner {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .openday-logo {
      width: 65%;
      margin: auto;
      padding-bottom: 53px;
    }

    .title {
      padding-bottom: 10px;
      margin: 10px 0.8rem;

      .top {
        font-size: clamp(1.5625rem, 1.3542rem + 1.1111vw, 2.1875rem);
        text-align: left;
      }

      .middle {
        font-size: clamp(2.6875rem, 2.3333rem + 1.8889vw, 3.75rem);
        text-align: center;
        text-transform: uppercase;
      }

      .bottom {
        font-size: clamp(2.1875rem, 1.9792rem + 1.1111vw, 2.8125rem);
        text-align: right;
      }
    }
  }
  .date-info {
    .date {
      font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
      margin-bottom: 7px;
      text-align: center;
      color: #fff;
    }
  }
`

// Estilo da Imagem do Banner
const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

// Caixa do Formulário sobreposta na parte inferior direita
const FormBox = styled.div`
  background-color: #fff;
  padding: 50px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 46px;

  .form-title {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #000;
  }
  .sub-text {
    font-size: 24px;
    color: #e6d3c3;
    text-align: right;
    padding: 9px 0 69px 0;
  }
`

// Caixa para o Texto na parte inferior esquerda
const TextBox = styled.div`
  padding: 150px 0 100px 0;
  margin-top: 100vh;
  grid-column: 1 / -1;
`

// Estilo para o título, subtítulo e texto
const Title = styled.h2`
  font-size: clamp(1.875rem, 1.6667rem + 1.1111vw, 2.5rem);
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #000;
`

const SubTitle = styled.h2`
  font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
  margin-bottom: 45px;
  color: #000;
  margin-top: -1rem;
`

const Description = styled.p`
  font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
  line-height: 1.5;
  color: #000;

  li {
    list-style-type: none;
  }
  ul {
    margin-left: 40px;
    padding-top: 20px;
  }
`

// Estilo do Formulário
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  .input-box-field {
    margin-bottom: 42px;
  }

  .btn {
    display: flex;
    justify-content: center;

    .btnMarcar {
      width: calc(140px + 60 * (100vw - 320px) / 1600);
      height: calc(30px + 24 * (100vw - 320px) / 1600);
      font-size: calc(14px + 6 * (100vw - 320px) / 1600);

      @media only screen and (min-width: 1920px) {
        font-size: 20px;
        width: 200px;
        height: 54px;
      }

      letter-spacing: 0.1em;
      background-color: #b7a99a;
      color: #ffffff;
      text-align: center;
      line-height: 0;
      z-index: 1;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      user-select: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .btn img {
      height: 50%;
    }
  }
  .input-box {
    position: relative;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      width: 50vw;
    }

    .radioNews {
      display: flex;
      width: 100%;
      margin-bottom: 42px;

      .textflex {
        width: 100%;
      }
    }

    .setaDown {
      position: absolute;
      width: 16px;

      @media only screen and (max-width: 768px) {
        width: calc(15px + 5 * (100vw - 320px) / 448);
      }

      right: 10px;
      top: 45%;
      transform: translateY(-20%);
      pointer-events: none;
      cursor: pointer;
      z-index: 3;
    }

    .label {
      position: absolute;
      top: -50%;
      left: 0%;
    }

    input,
    select {
      z-index: 1;
      padding: 0 3%;
      height: 50px;
      border: 1px solid rgb(183, 169, 154);
      outline: none;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 50px;

      @media only screen and (max-width: 1440px) {
        margin-bottom: 25px;
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";

      option {
        font-size: 18px;
      }
    }

    label,
    input {
      text-transform: uppercase;
      font-size: 15px;
      color: rgb(35, 31, 32);
      font-weight: 300;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__tab-loop {
      position: relative;
    }

    .react-datepicker-popper {
      position: absolute;
      top: 0;
      transform: translateY(-65%) !important;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .radioOptions {
      display: flex;

      .radioOption {
        display: flex;
        gap: 5%;
        align-items: center;
        color: #808080;
        cursor: pointer;
        margin-top: 1%;

        .checkmark {
          width: 30px;
          height: 30px;
          margin-right: 20px;
          cursor: pointer;

          img {
            width: 20px;
            height: 30px;
            padding: 0%;
            margin: 0%;
          }
        }
      }
    }
  }
`

const ResponsePop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow: hidden !important;
  background: #000000d9;
  -webkit-backdrop-filter: blur(50px);

  .response {
    background: #e6d1c0;
    padding: 124px 20px;
    z-index: 99;
    text-align: center;
    font-size: clamp(1.25rem, 1.0417rem + 1.1111vw, 1.875rem);
    margin: 0 5vw;
  }
`

const BannerWithTextAndFormMobile = () => {
  const form = useRef()
  const [response, setResponse] = useState("")
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const [name, setName] = useState("");
  const [selectedClinic, setSelectedClinic] = useState("default");
  const [error, setError] = useState("")

  const validateName = (name) => {
    const namePattern = /^[A-Za-zÀ-ÿ\s]+$/;  // Expressão regular para letras (incluindo acentuadas)
    return namePattern.test(name);         // Retorna true se a expressão regular passar
  };

  const handleClinicChange = (e) => {
    setSelectedClinic(e.target.value);
    console.log("CLÍNICA SELECIONADA: " + e.target.value);
  };

  var checker = []

  function doSubmit(e) {
    e.preventDefault()
    checkValues()

    // Verificar se a clínica foi selecionada
    if (selectedClinic === "default") {
      setResponse("Por favor, selecione uma clínica.");
      console.log("CLÍNICA SELECIONADA: " + selectedClinic);
      return;
    }

    // Verificar se nome tem apenas letras
    if (!validateName(name)) {
      setError('O nome deve conter apenas letras.');
      return;
    }

    if (checker.length < 1) {
      e.preventDefault()
      setResponse("")
      var formData = new FormData()
      formData.append("name", document.querySelector("#nameConsulta").value)
      formData.append("email", document.querySelector("#emailConsulta").value)
      formData.append("phone", document.querySelector("#phoneConsulta").value)
      {
        selectedClinic === "default" ?
          setResponse("Por favor, selecione uma clínica") :
          formData.append("clinica", document.querySelector("#clinicConsulta").value)
      }
      formData.append("opendayimplantologia", true)
      formData.append("newsletter", newsCheck ? true : false)

      for (const value of formData.values()) {
        console.log(value);
      }

      axios
        .post("https://carreiradentalclinic.pt/form-contact.php", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data; charset=UTF-8",
          },
        })
        .then(res => {
          console.log(res)
          res.data === "success" || res.data === "successsuccess"
            ? success()
            : setResponse(res.data)
        })
    } else {
      e.preventDefault()
      setResponse("Faltam os seguintes campos: " + checker + ".")
    }
  }

  const success = () => {
    var clinicTosend = document.querySelector("#clinicConsulta").value

    setResponse("Formulário enviado com sucesso!")
    setIsPopupVisible(true)
    fbTrack("trackCustom", "Envio de formulário - Página de Open Day")
    if (typeof window !== "undefined") {
      window.gtag("event", "form_open_day_implantologia", {
        form: "Formulário Open Day - Implantologia",
        clinica: clinicTosend,
      })
    }

    form.current.reset()
  }
  function checkValues() {
    checker = []
    let checkerTemp = []
    if (!document.querySelector("#nameConsulta").value) {
      checkerTemp.push("nome")
    }
    if (!document.querySelector("#emailConsulta").value) {
      checkerTemp.push("email")
    }
    if (document.querySelector("#phoneConsulta").value === "true") {
      checkerTemp.push("telefone")
    }
    if (document.querySelector("#clinicConsulta").value === "true") {
      checkerTemp.push("clínica")
    }
    if (!polCheck) {
      if (checkerTemp === []) {
        checkerTemp.push("aceitar os nossos termos e políticas")
      } else {
        checkerTemp.push("e aceitar os nossos termos e políticas")
      }
    }
    checker = checkerTemp
  }

  const [newsCheck, setNewsCheck] = useState(false)
  const [polCheck, setPolCheck] = useState(false)

  function checkNewsletter() {
    document.querySelector("#newsletter").checked = !document.querySelector(
      "#newsletter"
    ).checked
    setNewsCheck(!newsCheck)
  }
  function checkPolitica() {
    document.querySelector("#politica").checked = !document.querySelector(
      "#politica"
    ).checked
    setPolCheck(!polCheck)
  }

  const handleCloseSuccess = () => {
    setIsPopupVisible(false) // Função para fechar o popup
  }

  return (
    <BannerContainer>
      {/* Imagem do Banner */}
      <BannerImage src={banner} alt="Banner" />
      <div className="openday-implantologia">
        <div className="info-banner">
          <div className="title">
            <p className={font.rR + " top"}>Novembro mês da</p>
            <p className={font.rB + " middle"}>implantologia</p>
            <p className={font.rR + " bottom"}> avançada</p>
          </div>
        </div>
        <div className="date-info">
          <p className={font.rR + " date"}>
            <b>26 Nov •</b> Oliveira de Azeméis
            <br />
            <b>28 e 29 Nov •</b> Porto
            <br />
            <b>03 Dez •</b> Viseu
            <br />
            <b>05 Dez •</b> Águeda
            <br />
            <b>06 Dez •</b>Aveiro
            <br />
          </p>

          <FormBox id="form-openday">
            <div>
              <p className={font.rM + " form-title"}>Garanta já a sua vaga!</p>
              <p className={font.rB + " sub-text"}>VAGAS LIMITADAS!</p>
            </div>
            <StyledForm method="post" action="" iv className="form" ref={form}>
              <div className="input-box">
                <label className={font.rB}>Clínica *</label>
                <select
                  name="clinica"
                  className={font.rM}
                  id="clinicConsulta"
                  defaultValue="default"
                  required
                  onChange={handleClinicChange}
                >
                  <option value="default" disabled hidden>Selecione uma clínica</option>
                  <option value="Porto">Porto</option>
                  <option value="Aveiro">Aveiro</option>
                  <option value="Águeda">Águeda</option>
                  <option value="Viseu">Viseu</option>
                  <option value="Oliveira de Azeméis">
                    Oliveira de Azeméis
                  </option>
                </select>
                <img src={setaDown} alt="Selecionar" className="setaDown" />
              </div>
              <div className="input-box">
                <div style={{ width: '100%' }}>
                  <label className={font.rB}>Nome *</label>
                  <input
                    type="text"
                    name="nome"
                    className={font.rM}
                    id="nameConsulta" style={{ textTransform: "none" }}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                  {name && !/^[a-zA-Z]+$/.test(name) && (
                    <div style={{ color: 'red', fontSize: '0.8rem', textAlign: 'center' }} className="error">O nome deve conter apenas letras.</div>
                  )}
                </div>
              </div>
              <div className="input-box">
                <label className={font.rB}>Email *</label>
                <input
                  type="text"
                  name="email"
                  className={font.rM}
                  id="emailConsulta"
                  style={{ textTransform: "none" }}
                  required
                />
              </div>
              <div className="input-box" id="telefone">
                <label className={font.rB}>Telefone *</label>
                <input
                  type="text"
                  name="telefone"
                  className={font.rM}
                  id="phoneConsulta"
                  style={{ textTransform: "none" }}
                  required
                />
              </div>
              <div className="input-box-field">
                <span className={font.rR}>
                  <b>* CAMPOS DE PREENCHIMENTO OBRIGATÓRIO</b>
                </span>
                <br />
                <br />
                <span className={font.rR}>
                  Este formulário de contacto corresponde ao procedimento normal
                  de envio de um email diretamente para a nossa caixa de
                  correio. Os seus dados não são armazenados nem utilizados para
                  qualquer outro fim.
                </span>
              </div>
              <div className="input-box">
                <div className="radioNewsAll">
                  <label className="radioNews">
                    <span className="checkmark">
                      <img
                        src={newsCheck ? checked : checkbox}
                        alt="Newsletter"
                      />
                    </span>
                    <input
                      type="checkbox"
                      name="newsletter"
                      value="newsletter"
                      id="newsletter"
                      onClick={checkNewsletter}
                    />
                    <p className={font.rSB} style={{ paddingLeft: "23px" }}>
                      Quero receber notícias de saúde oral no meu email
                    </p>
                  </label>
                  <label className="radioNews">
                    <span className="checkmark">
                      <img
                        src={polCheck ? checked : checkbox}
                        alt="Política de Privacidade"
                      />
                    </span>
                    <input
                      type="checkbox"
                      name="politica"
                      value="politica"
                      id="politica"
                      onClick={checkPolitica}
                    />
                    <p
                      className={font.rSB + " textflex"}
                      style={{ paddingLeft: "23px" }}
                    >
                      Aceito os
                      <Link to="/politica_de_privacidade">
                        &nbsp;termos de política e privacidade*
                      </Link>
                    </p>
                  </label>
                </div>
              </div>
              <div className="btn">
                <Button
                  styleless
                  className="btnMarcar"
                  onClick={e => doSubmit(e)}
                >
                  <p className={font.rSB}>ENVIAR</p>
                </Button>
              </div>
            </StyledForm>
            <div
              className={font.rM + " response"}
              id={
                response === "Formulário enviado com sucesso!"
                  ? "contacto-sucesso"
                  : "contacto-fail"
              }
              style={{ color: '#000', textAlign: 'center', marginTop: '2vh', fontWeight: '500' }}
            >
              {response}
            </div>
          </FormBox>
        </div>

        {/* Caixa de Texto na parte inferior esquerda */}
      </div>
      {isPopupVisible && (
        <ResponsePop>
          <div
            className={font.rB + " response"}
            id={
              response === "Formulário enviado com sucesso!"
                ? "contacto-sucesso"
                : "contacto-fail"
            }
          >
            <div onClick={handleCloseSuccess} className="close-btn">
              X
            </div>
            A SUA MENSAGEM FOI ENVIADA COM SUCESSO. PROMETEMOS SER BREVES NA
            RESPOSTA.
            <br />
            <br />
            <p>Até já!</p>
          </div>
        </ResponsePop>
      )}
      <div className="container-layout">
        <div className="grid-container">
          <TextBox>
            <Title className={font.rB}>CONDIÇÕES ESPECIAIS</Title>
            <SubTitle className={font.rR}>
              Para recuperar o seu bem-estar!
            </SubTitle>
            <Description className={font.rR}>
              No mês da Implantologia Avançada, na clínica dentária CARREIRA
              DENTAL CLINIC, descubra como a <b>dentição fixa com implantes,</b>
              com recurso à <b>técnica All-on-4,</b> é a solução mais cómoda,
              rápida e eficaz para recuperar o seu sorriso.
              <ul className={font.rB}>
                <li> • Raio-X Panorâmico;</li> <li> • Scan Intraoral;</li>
                <li> • Registo Fotográfico.</li>
              </ul>
            </Description>
          </TextBox>
        </div>
      </div>
    </BannerContainer>
  )
}

export default BannerWithTextAndFormMobile
